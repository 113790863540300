<style scoped>
.c-progress-button {
  opacity: unset !important;
  cursor: not-allowed;
}
@media (max-width: 1024px) {
  .c-sm-spacer {
    margin-top: 1rem;
  }
}
</style>
<script>

import {get_acsrf_token} from "@/methods";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {mapGetters} from "vuex";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import {PaymentGateway, PaymentMethodType} from "@/enums";
import {loadStripe} from "@stripe/stripe-js";

export default {
  props: ['plan', 'planInfo'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
	  HalfCircleSpinner
  },
  validations: {

  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
      'getDTLocale'
    ])
  },
  methods: {
    async retrievePaymentGateways() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateways`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.paymentGateways = data.payment_gateways;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async processPaymentGatewayRequirements(data) {
      switch(data.gateway) {
        case PaymentGateway.STRIPE: {
          let result = await this.stripeObject.confirmCardPayment(
              data.client_secret,
              {
                payment_method: data.payment_method_id
              }
          );
          if(result.error) {
            this.$swal({
              icon: 'error',
              text: this.$t('billing.payment.confirm.error')
            });
            if(data.invoice_id) {
              try {
                let acsrf_token = await get_acsrf_token();
                let payload = {
                  acsrf_token: acsrf_token,
                  invoice_id: data.invoice_id
                };
                let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateway/deny`, {
                  method: 'POST',
                  body: JSON.stringify(payload),
                  credentials: 'include'
                });
                if(!response.ok) {
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                }
              } catch (error) {
                console.log(`[ERROR] ${error}`);
              }
            }
          }
          break;
        }
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    canContinue() {
      return true;
    },
    async doContinue() {
      if(!this.canContinue()) return;
      this.processing = true;
      this.$emit('frameCompleted');
    }
  },
  created() {
    try {
      this.$socket.client.on('billing:gateway:approve', this.processPaymentGatewayRequirements);
    } catch (e) {
      // nothing
    }
  },
  async mounted() {
    await this.retrievePaymentGateways();
    if (!this.error) {
      this.stripeObject = await loadStripe(this.paymentGateways.stripe.pk);
      this.stripeElements = this.stripeObject.elements();
      this.ready = true;
    }
    this.totalCost = this.planInfo.cost;
    if(this.planInfo.change) {
      this.totalCost += this.planInfo.change.upgrade_cost;
    }
    this.$emit('frameReady');
  },
  destroyed() {
    try {
      this.$socket.client.off('billing:gateway:approve', this.processPaymentGatewayRequirements);
    } catch (e) {
      // nothing
    }
  },
  data() {
    return {
      PaymentGateway:PaymentGateway,
      PaymentMethodType:PaymentMethodType,
      ready: false,
      error: false,
      processing: false,
      paymentGateways: null,
      totalCost: 9999.99
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-sm-12">
          <div class="card card-body shadow shadow-lg text-center">

            <template v-if="planInfo.instant_activation">
              <h3 class="mb-1">
                {{ $t('billing.checkout.summary.free.title') }}
              </h3>
              <h1 class="mb-1">
                {{ planInfo.name }}
              </h1>
              <h5 class="text-muted">
                {{ $t('billing.checkout.summary.free.disclaimer') }}
              </h5>
            </template>
            <template v-else>
              <h3 class="mb-1">
                {{ planInfo.name }}
              </h3>
              <h1 class="mb-1">
                {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(planInfo.cost) }}
              </h1>
              <h5 class="text-muted">
                {{$t('billing.subscription.plans.period.monthly')}}
              </h5>
            </template>
            <template v-if="planInfo.change">
              <hr>
              <div>
                <h4 class="mb-2">
                  <template v-if="planInfo.change.is_upgrade">
                    <small class="badge badge-primary small">
                      {{ $t('billing.checkout.summary.change.upgrade') }}
                    </small>
                  </template>
                  <template v-else>
                    <small class="badge badge-warning text-black small">
                      {{ $t('billing.checkout.summary.change.downgrade') }}
                    </small>
                  </template>
                  <br>
                  {{ $t('billing.checkout.summary.change.info', {previous_plan: planInfo.change.current_plan.name, new_plan: planInfo.name}) }}
                  <div class="small text-muted">
                    <template v-if="planInfo.change.effective_date">
                      {{ $t('billing.checkout.summary.change.date', {date: $d(parseDate(planInfo.change.effective_date), 'datetime', getDTLocale())}) }}
                    </template>
                    <template v-else>
                      {{ $t('billing.checkout.summary.change.date_now') }}
                    </template>
                  </div>
                </h4>
              </div>
              <template v-if="planInfo.change.upgrade_cost">
                <h2 class="mb-1">
                  {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(planInfo.change.upgrade_cost) }}
                </h2>
                <h5 class="text-muted">
                  <template v-if="planInfo.change.upgrade_cost > 1.00">
                    {{$t('billing.subscription.plans.period.immediately')}}
                  </template>
                  <template v-else>
                    {{$t('billing.subscription.plans.period.next_bill')}}
                  </template>
                </h5>
              </template>
              <template v-else>
                <h5 class="text-muted">
                  {{ $t('billing.checkout.summary.free.disclaimer') }}
                </h5>
              </template>
              <hr>
            </template>

            <div class="text-center mt-2">
              <button class="btn btn-lg" :class="{'btn-primary': canContinue(), 'btn-warning': !canContinue(), 'disabled': !canContinue() || processing}" :disabled="!canContinue() || processing" v-on:click="doContinue()">
                <h5 class="text-uppercase mb-0">
                  <half-circle-spinner
                      v-if="processing"
                      :animation-duration="900"
                      :size="18"
                      class="align-middle d-inline-block"
                  />
                  <template v-if="planInfo.instant_activation">
                    {{ $t('billing.checkout.summary.activate') }}
                  </template>
                  <template v-else>
                    {{ $t('billing.checkout.summary.order') }}
                  </template>
                </h5>
              </button>
            </div>

            <div class="row mt-4 justify-content-center" v-if="!planInfo.instant_activation">
              <div class="col-lg-8 col-sm-12">
                <span class="small text-muted">
                  {{ $t('billing.checkout.summary.withdrawal.disclaimer') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>
  </div>
</template>
